
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { PAGE_TITLE_PATH } from "@/presentation/constants/PageTitle";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import store from "@/presentation/store";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { useRoute, useRouter } from "vue-router";
import { ProductListModel } from "@/domain/product/product-list/model/ProductListModel";
import { BuyProductModel } from "@/domain/product/buy-product/model/BuyProductModel";
import Swal from "sweetalert2";
import { UpdateProductModelSettingModel } from "@/domain/product/update-product-model-setting/model/UpdateProductModelSettingModel";
import { MenuComponent } from "@/presentation/assets/ts/components";
import { GetProductModel } from "@/domain/product/get-product/model/GetProductModel";
import { ModuleRoutes } from "@/domain/constants/ModuleRoutes";

export default defineComponent({
  name: "Profilim",
  components: { Field, Form, ErrorMessage },
  data() {
    return {
      activeTab: 0, // Default olarak ilk sekmeyi seçili yap
    };
  },
  methods: {
    handleModelChange(event) {
      const selectedModelSettingId = event.target.value;
      this.updateProductModelSetting(Number(selectedModelSettingId));
    },
  },
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const isLoading = ref<boolean>(false);
    const swalNotification = new SwalNotification();

    const boughtModule = ref<boolean>(false);

    const scrollElRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);

    const productController = store.state.ControllersModule.productController;
    const module = ref<ProductListModel>({
      product: {
        productPackages: [
          {
            purchased: false,
            productModelSettings: [],
          },
        ],
      },
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else return text;
    };

    const getModule = async () => {
      isLoading.value = true;

      const productListModel: ProductListModel = {
        product: {
          productPackages: [],
        },
        locale: "tr",
      };

      productController
        .productList(productListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((product) => {
              if (product.product.shortCode == route.params["id"])
                module.value = product;
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        })
        .finally(() => {
          if (!module.value.product.id) {
            router.push({ name: "moduleList" });
          }
        });
    };

    const buyModule = async (productPackage: number) => {
      const buyProductModel: BuyProductModel = {
        productPackageId: productPackage,
      };

      productController
        .buyProduct(buyProductModel)
        .then((response) => {
          if (response.isSuccess) {
            boughtModule.value = true;
            console.log("ilk öncesi", store.getters.buyModuleCount);
            store.dispatch("buyModuleCount", boughtModule.value);
            console.log("sonrası", store.getters.buyModuleCount);

            Swal.fire({
              text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_BUY_PRODUCT),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t("common.button.ok"),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              //    router.push({ name: "hotelManagement" });
              location.reload();
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const updateProductModelSetting = async (productModelSetting: number) => {
      console.log("productModelSetting", productModelSetting);
      const updateProductModelSettingModel: UpdateProductModelSettingModel = {
        productId: module.value.product.id,
        productModelSetting: productModelSetting,
      };

      productController
        .updateProductModelSetting(updateProductModelSettingModel)
        .then((response) => {
          if (response.isSuccess) {
            Swal.fire({
              text: t(
                SWAL_MESSAGES.SUCCESS.SUCCESS_UPDATE_PRODUCT_MODEL_SETTING
              ),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t("common.button.ok"),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              router.push({ name: "hotelManagement" });
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const navigateToModule=(moduleShortcode)  => {
      const route = ModuleRoutes[moduleShortcode];
      if (route) {
        router.push(route);  // Vue Router kullanarak yönlendirme
      } else {
        console.error('Geçersiz modül adı:', moduleShortcode);
      }
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.detail.pageTitle"));
      getModule();

      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    return {
      PAGE_TITLE_PATH,
      translate,
      module,
      buyModule,
      isLoading,
      updateProductModelSetting,
      navigateToModule,
    };
  },
});
